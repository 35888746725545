import React, {useContext, useEffect, useState} from "react";
// import CONFIG from '../../config.json';
// import {AlertContext} from "../../contexts/AlertContext";
// import {request} from "../../axios/axios-instance";
import {CardBig} from "../CardBig";

export const HousePlanComponent = () => {
  // const [temperature, setTemperature] = useState(0);

  useEffect(async () => {
    try {
      // const response = await request
      //     .get(`${CONFIG.BACKEND_URL}/weather/temperature`)
      // setTemperature(response.data.value);

      // todo: strzał po wszystkie parametry temp i wilg co potrzebne
    } catch (e) {
      alert.error(e);
    }
  }, []);

  const createInfoBox = (roomName, temperature, humidity, brightness) => {
    return (
        <div className="data-box-ambient">
          <div className="row m-auto mb-1 title">{roomName}</div>
          <div className="row">
            <span className="col text-danger"><i className="fas fa-thermometer-half "/><br/>{temperature}°C</span>
            <span className="col text-info"><i className="fas fa-tint"/><br/>{humidity}%</span>
            {/*<span className="col text-warning"><i className="fas fa-sun"/><br />{brightness}%</span>*/}
          </div>
        </div>
    )
  }

  return (
      <CardBig title="Plan piętra">
        <div className="house-plan-level-1">
          <div className="row">
            <div className="col-7"></div>
            <div
                className="col-5 justify-content-start align-items-end">{createInfoBox("Chill room", 12.4, 44.5, 33)}</div>
          </div>
          <div className="row">
            <div
                className="col-7 align-items-start justify-content-center">{createInfoBox("Sypialnia", 12.4, 44.5, 33)}</div>
            <div
                className="col-5 justify-content-start align-items-center">{createInfoBox("Garderoba", 12.4, 44.5, 33)}</div>
          </div>
          <div className="row">
            <div className="col-6 justify-content-end align-items-center">{createInfoBox("Korytarz", 12.4, 44.5, 33)}</div>
          <div className="col-6"></div>
        </div>
        <div className="row">
          <div className="col-6 justify-content-end align-items-center">{createInfoBox("Łazienka", 12.4, 44.5, 33)}</div>
          <div className="col-6 justify-content-center align-items-start">{createInfoBox("Biuro", 12.4, 44.5, 33)}</div>
        </div>
      </div>
    </CardBig>
  );
};
