import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const SmartPlugPage = () => {
  const { deviceId } = useParams();
  const { targetDevice, devices } = useContext(DevicesContext);

  useEffect(() => {
    targetDevice.set(deviceId);
  }, [deviceId, devices]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

  return (
    <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4"></div>
      </div>
    </div>
  );
};
