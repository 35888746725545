import React, {createContext, useMemo} from "react";
import PropTypes from "prop-types";
import {toast, ToastContainer, Slide} from "react-toastify";

export const AlertContext = createContext();
let prevErrorMsg = ''

export const AlertProvider = (props) => {
  const value = useMemo(() => ({
    success: toast.success,
    info: toast.info,
    error: (error) => {
      const msg = typeof error === "string" ? error : error.message

      if (prevErrorMsg !== msg) {
        prevErrorMsg = msg
        toast.error(msg);
      }
    },
  }), []);

  return (
    <AlertContext.Provider value={value}>
      {props.children}
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        theme={'colored'}
        limit={3}
      />
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
