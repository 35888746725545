import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import { GasSensorCard } from "../components/smart-sensors/gas-sensor/GasSensorCard";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const GasSensorPage = () => {
  const { deviceId } = useParams();
  const { targetDevice, isDevicesFetched, devices } = useContext(DevicesContext);

  useEffect(() => {
    if (isDevicesFetched && uuidRegex.test(deviceId)) {
      targetDevice.set(deviceId);
    }
  }, [deviceId, devices]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

  return (
    <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <GasSensorCard />
        </div>
      </div>
    </div>
  );
};
