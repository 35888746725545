import React, { useContext, useEffect, useState } from "react";
import { CardBig } from "../../CardBig";
import CONFIG from '../../../config.json';
import { AlertContext } from "../../../contexts/AlertContext";
import { DevicesContext } from "../../../contexts/DevicesContext";
import PropTypes from "prop-types";
import {request} from "../../../axios/axios-instance";
import {UserContext} from "../../../contexts/UserContext";

export const GrowboxConfigPanel = (props) => {
  const [config, setConfig] = useState({
    humidityThreshold: 0,
    temperatureThreshold: 0,
    hpsStartHour: 0,
    hpsEndHour: 0
  });
  const alert = useContext(AlertContext);
  const { user } = useContext(UserContext);
  const { targetDevice } = useContext(DevicesContext);

  const getConfig = async () => {
    try {
      const response = await request
          .authorized(await user.getToken())
          .get(`${CONFIG.BACKEND_URL}/devices/${props.device?.id ?? targetDevice.device?.id}/config`)

      setConfig({
        humidityThreshold: parseFloat(response.data.humidityThreshold),
        temperatureThreshold: parseFloat(response.data.temperatureThreshold),
        hpsStartHour: parseInt(response.data.hpsStartHour),
        hpsEndHour: parseInt(response.data.hpsEndHour),
      });
    } catch (e) {
      alert.error(e)
    }
  };

  useEffect(() => {
    if (!targetDevice.device && !props.device) {
      return;
    }

    getConfig();
  }, [targetDevice]);

  const saveConfig = async () => {
    try {
      await request
        .authorized(await user.getToken())
        .patch(
          `${CONFIG.BACKEND_URL}/devices/${props.device?.id ?? targetDevice.device?.id}/config`,
            {
              humidityThreshold: config.humidityThreshold,
              temperatureThreshold: config.temperatureThreshold,
              hpsStartHour: config.hpsStartHour,
              hpsEndHour: config.hpsEndHour
            }
        )

      alert.success('Data saved.')
    } catch (e) {
      alert.error(e)
    }
  };

  const setConfigProperty = (key, value) => {
    const toChange = {}

    toChange[key] = parseFloat(value)

    setConfig({
      ...config,
      ...toChange
    })
  }

  return (
    <CardBig title={`${props.device?.name ?? targetDevice.device?.name} - Config Panel`}>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Humidity threshold:</div>
        <div className="col">
          <input
            className="form-control"
            type="number"
            onChange={(event) => setConfigProperty('humidityThreshold', event.target.value)}
            value={config.humidityThreshold}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Temperature threshold:</div>
        <div className="col">
          <input
            className="form-control"
            type="number"
            onChange={(event) => setConfigProperty('temperatureThreshold', event.target.value)}
            value={config.temperatureThreshold}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">HPS start hour:</div>
        <div className="col">
          <input
              className="form-control"
              type="number"
              onChange={(event) => setConfigProperty('hpsStartHour', event.target.value)}
              value={config.hpsStartHour}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">HPS end hour:</div>
        <div className="col">
          <input
              className="form-control"
              type="number"
              onChange={(event) => setConfigProperty('hpsEndHour', event.target.value)}
              value={config.hpsEndHour}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col text-center">
          <button className="btn btn-primary" onClick={saveConfig}>
            Save
          </button>
        </div>
      </div>
    </CardBig>
  );
};

GrowboxConfigPanel.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};