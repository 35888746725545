import React, { useContext, useEffect, useState } from "react";
import { CardBig } from "../../CardBig";
import CONFIG from '../../../config.json';
import { AlertContext } from "../../../contexts/AlertContext";
import { DevicesContext } from "../../../contexts/DevicesContext";
import PropTypes from "prop-types";
import {Switch} from "../../basic/Switch";
import {request} from "../../../axios/axios-instance";
import {UserContext} from "../../../contexts/UserContext";

const MODE = {
  MANUAL: "manual",
  AUTO: "auto",
};

const DEVICE_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export const GrowboxActionPanel = (props) => {
  const [properties, setProperties] = useState({
    mode: {value: MODE.MANUAL},
    fan: {value: DEVICE_STATUS.INACTIVE},
    hps: {value: DEVICE_STATUS.INACTIVE},
    heater: {value: DEVICE_STATUS.INACTIVE},
    humidifier: {value: DEVICE_STATUS.INACTIVE},
  })
  const alert = useContext(AlertContext);
  const { targetDevice } = useContext(DevicesContext);
  const { user } = useContext(UserContext);

  const getProperties = async () => {
    try {
      const response = await request
          .authorized(await user.getToken())
          .get(`${CONFIG.BACKEND_URL}/devices/${props.device?.id ?? targetDevice.device?.id}`);

      setProperties(response.data);
    } catch (e) {
      alert.error(e)
    }
  };

  useEffect(async () => {
    if (!targetDevice.device && !props.device) {
      return;
    }

    if (props.device) {
      await getProperties()
    } else if (targetDevice.device) {
      setProperties(targetDevice.properties)
    }

  }, [targetDevice]);

  const runAction = async (action, updatedPropertyKey, data) => {
    try {
      await request
        .authorized(await user.getToken())
        .post(`${CONFIG.BACKEND_URL}/devices/${props.device?.id ?? targetDevice.device?.id}/actions/${action}`, data)

      setProperties({
        ...properties,
        [updatedPropertyKey]: data
      })
    } catch (e) {
      alert.error(e)
    }
  }

  return (
    <CardBig title={`${props.device?.name ?? targetDevice.device?.name} - Actions Panel`}>
      <div className="row">
        <div className="col-sm-4 vertical-center">Mode:</div>
        <div className="col">
          <Switch
              callback={(event) => runAction('changeMode', 'mode',{value: event.target.value})}
              checked={properties.mode?.value ?? false}
              options={[
                {
                  name: "manual",
                  value: MODE.MANUAL,
                },
                {
                  name: "auto",
                  value: MODE.AUTO,
                },
              ]}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Ventilation:</div>
        <div className="col">
          <Switch
              callback={(event) => runAction('fanOnOff', 'fan',{value: event.target.value === 'true'})}
              checked={properties.fan?.value ?? false}
              options={[
                {
                  name: "active",
                  value: DEVICE_STATUS.ACTIVE,
                },
                {
                  name: "inactive",
                  value: DEVICE_STATUS.INACTIVE,
                },
              ]}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">HPS:</div>
        <div className="col">
          <Switch
              callback={(event) => runAction('hpsOnOff', 'hps', {value: event.target.value === 'true'})}
              checked={properties.hps?.value ?? false}
              options={[
                {
                  name: "active",
                  value: DEVICE_STATUS.ACTIVE,
                },
                {
                  name: "inactive",
                  value: DEVICE_STATUS.INACTIVE,
                },
              ]}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Heater:</div>
        <div className="col">
          <Switch
              callback={(event) => runAction('heaterOnOff', 'heater', {value: event.target.value === 'true'})}
              checked={properties.heater?.value ?? false}
              options={[
                {
                  name: "active",
                  value: DEVICE_STATUS.ACTIVE,
                },
                {
                  name: "inactive",
                  value: DEVICE_STATUS.INACTIVE,
                },
              ]}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Humidifier:</div>
        <div className="col">
          <Switch
              callback={(event) => runAction('humidifierOnOff', 'humidifier',  {value: event.target.value === 'true'})}
              checked={properties.humidifier?.value ?? false}
              options={[
                {
                  name: "active",
                  value: DEVICE_STATUS.ACTIVE,
                },
                {
                  name: "inactive",
                  value: DEVICE_STATUS.INACTIVE,
                },
              ]}
          />
        </div>
      </div>
    </CardBig>
  );
};

GrowboxActionPanel.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};