import React, { useContext, useEffect, useState } from "react";
import { CardBig } from "../../CardBig";
import { Switch } from "../../basic/Switch";
import CONFIG from '../../../config.json';
import { AlertContext } from "../../../contexts/AlertContext";
import { DevicesContext } from "../../../contexts/DevicesContext";
import {request} from "../../../axios/axios-instance";
import {UserContext} from "../../../contexts/UserContext";

const MODE = {
  MANUAL: "manual",
  THERMOSTAT: "thermostat",
  HYGROSTAT: "hygrostat",
};

const DEVICE_STATUS = {
  ON: 'on',
  OFF: 'off',
};

export const HygrostatAndThermostatConfigAndActionsPanel = () => {
  const [mode, setMode] = useState(MODE.MANUAL);
  const [humidityThreshold, setHumidityThreshold] = useState(0);
  const [temperatureThreshold, setTemperatureThreshold] = useState(0);
  const [deviceStatus, setDeviceStatus] = useState(DEVICE_STATUS.OFF);
  const { user } = useContext(UserContext);
  const alert = useContext(AlertContext);
  const { targetDevice } = useContext(DevicesContext);

  useEffect(async () => {
    if (!targetDevice.isFetched) {
      return;
    }

    setDeviceStatus(
      targetDevice.properties.status.value === DEVICE_STATUS.OFF
        ? DEVICE_STATUS.OFF
        : DEVICE_STATUS.ON
    );
    setMode(targetDevice.properties.mode.value);

    try {
      const response = await request
        .authorized(await user.getToken())
        .get(`${CONFIG.BACKEND_URL}/devices/${targetDevice.device.id}/config`)
      setHumidityThreshold(parseFloat(response.data.humidityThreshold));
      setTemperatureThreshold(parseFloat(response.data.temperatureThreshold));
    } catch (e) {
      alert.error(e)
    }
  }, [targetDevice]);

  const handleChangeMode = async (event) => {
    const value = event.target.value;

    try {
      await request
          .authorized(await user.getToken())
          .post(
              `${CONFIG.BACKEND_URL}/devices/${targetDevice.device.id}/actions/changeMode`,
              { value }
          )

      setMode(value);
    } catch (e) {
      alert.error(e)
    }
  };

  const handleChangeDeviceStatus = async (event) => {
    const status =
      event.target.value === DEVICE_STATUS.OFF
        ? 0
        : 1;

    try {
      await request
        .authorized(await user.getToken())
        .post(
            `${CONFIG.BACKEND_URL}/devices/${targetDevice.device.id}/actions/onOff`,
            {
              value: status,
            }
        )

      setDeviceStatus(status ? DEVICE_STATUS.ON : DEVICE_STATUS.OFF);

      alert.success("Status changed.");
    } catch (e) {
      alert.error(e)
    }
  };

  const saveConfig = async () => {
    const data = {
      humidityThreshold,
      temperatureThreshold,
    };

    try {
      const response = await request
        .authorized(await user.getToken())
        .patch(
            `${CONFIG.BACKEND_URL}/devices/${targetDevice.device.id}/config`,
            data
        )

      setHumidityThreshold(parseFloat(response.data.humidityThreshold));
      setTemperatureThreshold(parseFloat(response.data.temperatureThreshold));

      alert.success("Saved");
    } catch (e) {
      alert.error(e);
    }
  };

  const handleInputHumidityThreshold = (event) => {
    setHumidityThreshold(parseFloat(event.target.value));
  };

  const handleInputTemperatureThreshold = (event) => {
    setTemperatureThreshold(parseFloat(event.target.value));
  };

  return (
    <CardBig title="Thermostat And Thermostat Config Panel">
      <div className="row">
        <div className="col-sm-4 vertical-center">Fan:</div>
        <div className="col">
          <Switch
            callback={handleChangeDeviceStatus}
            checked={deviceStatus}
            options={[
              {
                name: "active",
                value: DEVICE_STATUS.ON,
              },
              {
                name: "inactive",
                value: DEVICE_STATUS.OFF,
              },
            ]}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Mode:</div>
        <div className="col">
          <Switch
            callback={handleChangeMode}
            checked={mode}
            options={[
              {
                name: MODE.MANUAL,
                value: MODE.MANUAL,
              },
              {
                name: MODE.THERMOSTAT,
                value: MODE.THERMOSTAT,
              },
              {
                name: MODE.HYGROSTAT,
                value: MODE.HYGROSTAT,
              },
            ]}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Humidity threshold:</div>
        <div className="col">
          <input
            className="form-control"
            type="number"
            onChange={handleInputHumidityThreshold}
            value={humidityThreshold}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Temperature threshold:</div>
        <div className="col">
          <input
            className="form-control"
            type="number"
            onChange={handleInputTemperatureThreshold}
            value={temperatureThreshold}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col text-center">
          <button className="btn btn-primary" onClick={saveConfig}>
            Save
          </button>
        </div>
      </div>
    </CardBig>
  );
};
