import React, {useContext, useEffect} from "react";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import {Card} from "../components/Card";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const AmbientSensorPage = () => {
    const { deviceId } = useParams();
    const { targetDevice, devices } = useContext(DevicesContext);

    useEffect(async () => {
        targetDevice.set(deviceId);
    }, [deviceId, devices]);

    if (!uuidRegex.test(deviceId)) {
        return <span>Id is invalid</span>;
    }

    const statusIcon = (status) => {
        return status ? <i className="fas fa-check-circle text-success"/> : <i className="fas fa-check-circle text-danger"/>
    }

    return (
        <div className="container-fluid">
            <DevicePageTitle />
            <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                    <Card
                        title={`Temperatura`}
                        value={`${targetDevice.properties.temperature?.value}`}
                        icon="fa-thermometer-half"
                        color="danger"
                    />
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Card
                        title={`Wilgotność`}
                        value={`${targetDevice.properties.humidity?.value}`}
                        icon="fa-percent"
                        color="info"
                    />
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Card
                        title={`Naświetlenie`}
                        value={`${targetDevice.properties.brightness?.value}`}
                        icon="fa-sun"
                        color="warning"
                    />
                </div>
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-xl-6 col-lg-6">*/}
            {/*        <PropertiesChart*/}
            {/*            title="Temperature chart"*/}
            {/*            id="ambientTemperatureChart"*/}
            {/*            data={[*/}
            {/*                {*/}
            {/*                    deviceId,*/}
            {/*                    property: 'temperature',*/}
            {/*                    labelName: 'Temperature',*/}
            {/*                    mapValue: (value) => parseFloat(value),*/}
            {/*                    color: 'red'*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    deviceId,*/}
            {/*                    property: 'humidity',*/}
            {/*                    labelName: 'Humidity',*/}
            {/*                    mapValue: (value) => parseFloat(value),*/}
            {/*                    color: 'blue'*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    deviceId,*/}
            {/*                    property: 'brightness',*/}
            {/*                    labelName: 'Humi. out',*/}
            {/*                    mapValue: (value) => parseInt(value),*/}
            {/*                    color: 'orange'*/}
            {/*                },*/}
            {/*            ]}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};
