import React from "react";
import {HousePlanComponent} from "../components/house-plan/HousePlanComponent";

export const HousePlanPage = () => {
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">House Plan Page</h1>
        <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-12">
                <HousePlanComponent />
            </div>
        </div>
    </div>
  );
};
