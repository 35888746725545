import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import CONFIG from '../../config.json';
import PropTypes from "prop-types";
import {MultiLineChart} from "./MultiLineChart";
import {AlertContext} from "../../contexts/AlertContext";
import {request} from "../../axios/axios-instance";
import {UserContext} from "../../contexts/UserContext";

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const PropertiesChart = (props) => {
  const [properties, setProperties] = useState([]);
  const [datetimeFrom, setDatetimeFrom] = useState(
    moment().subtract(7, "d").format(DATETIME_FORMAT)
  );
  const [datetimeTo, setDatetimeTo] = useState(
    moment().format(DATETIME_FORMAT)
  );
  const [resolution, setResolution] = useState("1H");
  const { alert } = useContext(AlertContext)
  const { user } = useContext(UserContext);

  const convertPayloadToRecord = (payload, valueConverter) => {
    return {
      datetime: moment(payload.createdAt).format(DATETIME_FORMAT),
      value:
        typeof valueConverter === "function"
          ? valueConverter(payload.value.value)
          : payload.value.value,
    };
  };

  useEffect(async () => {
    try {
      const responses = await Promise.all(props.data.map(async (d) => {
        return {
          data: (await request
            .authorized(await user.getToken())
            .get(
              `${CONFIG.BACKEND_URL}/devices/${d.deviceId}/${d.property}/history`,
              {
                params: {
                  datetimeFrom,
                  datetimeTo,
                  resolution,
                },
              }
            )).data,
          labelName: d.labelName,
          color: d.color,
          mapValue: d.mapValue
        }
      }));

      setProperties(responses.map(response => ({
        values: response.data.map((payload) =>
            convertPayloadToRecord(payload, response.mapValue)
        ),
        labelName: response.labelName,
        color: response.color
      })))
    } catch (e) {
      alert.error(e)
    }
  }, [datetimeFrom, datetimeTo, resolution]);

  const changeDatetimeFromHandler = (date) => {
    setDatetimeFrom(moment(date).format(DATETIME_FORMAT));
  };

  const changeDatetimeToHandler = (date) => {
    setDatetimeTo(moment(date).format(DATETIME_FORMAT));
  };

  const changeResolutionHandler = (event) => {
    setResolution(event.target.value);
  };

  // TODO: co sie stanie jezeli daty nie beda sie pokryały z innymi zbiorami danych?
  const labels = properties[0]?.values.map(measure => moment(measure.datetime));
  const data = properties.map((property) => ({
    data: property.values.map(measure => measure.value),
    labelName: property.labelName,
    color: property.color,
  }));

  return (
    <MultiLineChart
      title={props.title}
      id={props.id}
      labels={labels ?? []}
      data={data}
      datetimeFrom={{
        value: moment(datetimeFrom).valueOf(),
        callback: changeDatetimeFromHandler,
      }}
      datetimeTo={{
        value: moment(datetimeTo).valueOf(),
        callback: changeDatetimeToHandler,
      }}
      resolution={{
        value: resolution,
        callback: changeResolutionHandler,
      }}
    />
  );
};

PropertiesChart.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    mapValue: PropTypes.func,
    color: PropTypes.string,
  })).isRequired
};
