import React, { useContext } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { LocationsContext } from "../../contexts/LocationsContext";

export const MenuLocations = () => {
  const { locations, targetLocation } = useContext(LocationsContext);

  const menuItems = locations.get().map((location) => (
    <MenuItem key={`menu-location-item-${location.id}`} value={location.id}>
      {location.name}
    </MenuItem>
  ));

  const handleChange = (event) => {
    targetLocation.set(event.target.value);
  };

  return (
    <li className="nav-item p10">
      <FormControl className="nav-form-control">
        <InputLabel
          shrink
          id="locationsLabel"
          htmlFor="locations"
          className="sidebar-heading"
        >
          Locations
        </InputLabel>
        <Select
          displayEmpty
          onChange={handleChange}
          labelId="locationsLabel"
          id="locations"
          inputProps={{
            name: "locations",
            id: "locations",
          }}
        >
          <MenuItem>None</MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    </li>
  );
};
