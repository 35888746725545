import "./scss/main.scss";
import React from "react";
import ReactDOM from "react-dom";
import { Dashboard } from "./Dashboard";
import { BrowserRouter as Router } from "react-router-dom";
import { AlertProvider } from "./contexts/AlertContext";
import { UserProvider } from "./contexts/UserContext";
import { DevicesProvider } from "./contexts/DevicesContext";
import { LocationsProvider } from "./contexts/LocationsContext";
import './axios/interceptors/double-request-eliminator.js'
import {Auth0Provider} from "@auth0/auth0-react";
import CONFIG from './config.json'

window.onload = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register(
      "https://iot-network.pl/service-worker.js"
    );
  }
};

ReactDOM.render(
  <Router>
    <Auth0Provider
      domain={CONFIG.OAUTH2_DOMAIN}
      clientId={CONFIG.OAUTH2_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={CONFIG.OAUTH2_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      maxAge={31_536_000} // year in seconds
    >
      <AlertProvider>
        <UserProvider>
          <LocationsProvider>
            <DevicesProvider>
              <Dashboard />
            </DevicesProvider>
          </LocationsProvider>
        </UserProvider>
      </AlertProvider>
    </Auth0Provider>
  </Router>,
  document.getElementById("app")
);
