import React, { useContext } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LocationsContext } from "../contexts/LocationsContext";
import CONFIG from "../config.json";
import { AlertContext } from "../contexts/AlertContext";
import { DevicesContext } from "../contexts/DevicesContext";
import { DeviceTypes } from "../types/deviceTypes";
import {UserContext} from "../contexts/UserContext";
import {request} from "../axios/axios-instance";

export const AddDevicePage = () => {
  const { locations } = useContext(LocationsContext);
  const { devices } = useContext(DevicesContext);
  const { user } = useContext(UserContext);
  const alert = useContext(AlertContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      type: "",
      locationId: "",
      historyLogging: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3, "Must be 3 characters or more").required(),
      url: Yup.string().url().required(),
      type: Yup.string().required(),
      locationId: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      try {
        await request
          .authorized(await user.getToken())
          .post(`${CONFIG.BACKEND_URL}/devices`, {
            ...values,
          }, {
            headers: {
              Authorization: `Bearer ${await user.getToken()}`
            },
          })

        devices.fetch();
        alert.success("Device was created!");
        formik.resetForm();
      } catch (e) {
        alert.error(e)
      }
    },
  });

  const typesOptions = Object.values(DeviceTypes).map((type) => (
    <option value={type}>{type}</option>
  ));

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">New Device</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col">
            <FormControl>
              <InputLabel htmlFor="name">Device name</InputLabel>
              <Input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <FormHelperText id="my-helper-text">
                  {formik.errors.name}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormControl>
              <InputLabel htmlFor="url">Device url</InputLabel>
              <Input
                id="url"
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.url && formik.errors.url ? (
                <FormHelperText id="my-helper-text">
                  {formik.errors.url}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormControl>
              <InputLabel htmlFor="type">Type</InputLabel>
              <NativeSelect
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{
                  name: "type",
                  id: "type",
                }}
              >
                <option aria-label="" value="" />
                {typesOptions}
              </NativeSelect>
              {formik.touched.type && formik.errors.type ? (
                <FormHelperText id="my-helper-text">
                  {formik.errors.type}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormControl>
              <InputLabel htmlFor="type">Location</InputLabel>
              <NativeSelect
                value={formik.values.locationId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{
                  name: "locationId",
                  id: "locationId",
                }}
              >
                <option aria-label="" value="" />
                {locations.get().map((location) => (
                  <option value={location.id}>{location.name}</option>
                ))}
              </NativeSelect>
              {formik.touched.locationId && formik.errors.locationId ? (
                <FormHelperText id="my-helper-text">
                  {formik.errors.locationId}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.historyLogging}
                  name="historyLogging"
                  color="default"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="History Logging"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
