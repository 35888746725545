import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Menu } from "./components/menu/Menu";
import { Topbar } from "./components/menu/Topbar";
import { Footer } from "./Footer";
import { MainPage } from "./pages/MainPage";
import { BlankPage } from "./pages/BlankPage";
import { HygrostatAndThermostatPage } from "./pages/HygrostatAndThermostatPage";
import { EnergyMeterPage } from "./pages/EnergyMeterPage";
import { RemoteGatePage } from "./pages/RemoteGatePage";
import { GasSensorPage } from "./pages/GasSensorPage";
import { SmartPlugPage } from "./pages/SmartPlugPage";
import { DevicesPage } from "./pages/DevicesPage";
import { AddDevicePage } from "./pages/AddDevicePage";
import {GrowboxPage} from "./pages/GrowboxPage";
import {AmbientSensorPage} from "./pages/AmbientSensorPage";
import {HousePlanPage} from "./pages/HousePlanPage";

export const Dashboard = () => {
  const [menuVisibility, setMenuVisibility] = useState(true);

  const toggleMenu = () => {
    setMenuVisibility(!menuVisibility);
  };

  return (
    <div id="wrapper">
      <Menu menuVisibility={menuVisibility} toggleMenu={toggleMenu} />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar menuVisibility={menuVisibility} toggleMenu={toggleMenu} />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/house-plan" element={<HousePlanPage />} />
            <Route path="/blank" element={BlankPage} />
            <Route
              path="/hygrostat-and-thermostat/:deviceId"
              element={<HygrostatAndThermostatPage />}
            />
            <Route
              path="/ambient-sensor/:deviceId"
              element={<AmbientSensorPage />}
            />
            <Route
              path="/energy-meter/:deviceId"
              element={<EnergyMeterPage />}
            />
            <Route path="/remote-gate/:deviceId" element={<RemoteGatePage />} />
            <Route path="/gas-sensor/:deviceId" element={<GasSensorPage />} />
            <Route path="/smart-plug/:deviceId" element={<SmartPlugPage />} />
            <Route path="/growbox/:deviceId" element={<GrowboxPage />} />
            <Route path="/devices/add" element={<AddDevicePage />} />
            <Route path="/devices" element={<DevicesPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};
