import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CONFIG from '../config.json';
import { UserContext } from "./UserContext";
import { AlertContext } from "./AlertContext";
import {request} from "../axios/axios-instance";

export const LocationsContext = createContext();

export const LocationsProvider = (props) => {
  const [locations, setLocations] = React.useState([]);
  const [targetLocation, setTargetLocation] = useState(null);
  const { user } = useContext(UserContext);
  const alert = useContext(AlertContext);
  const value = {
    locations: {
      get: () => locations,
      fetch: async () => {
        try {
          const response = await request
            .authorized(await user.getToken())
            .get(`${CONFIG.BACKEND_URL}/locations`)

          setLocations(response.data);
        } catch (e) {
          alert.error(e);
        }
      },
    },
    targetLocation: {
      location: targetLocation,
      set: (locationId) =>
        setTargetLocation(
          locations.find((location) => location.id === locationId)
        ),
    },
  };

  useEffect(async () => {
    if (user.isLoggedIn()) {
      await value.locations.fetch();
    }
  }, [user.user]);

  return (
    <LocationsContext.Provider value={value}>
      {props.children}
    </LocationsContext.Provider>
  );
};

LocationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.any,
};
