import React, { useContext, useEffect } from "react";
import { HygrostatAndThermostatConfigAndActionsPanel } from "../components/smart-sensors/hygrostat-and-thermostat/HygrostatAndThermostatConfigAndActionsPanel";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import {PropertiesChart} from "../components/basic/PropertiesChart";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";
import {Card} from "../components/Card";

export const HygrostatAndThermostatPage = () => {
  const { deviceId } = useParams();
  const { targetDevice, devices } = useContext(DevicesContext);

  useEffect(() => {
    targetDevice.set(deviceId);
  }, [deviceId, devices]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

  return (
    <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
            <Card
                title={`Temperatura - ${targetDevice.device?.name}`}
                value={`${targetDevice.properties.temperature?.value ?? '-'}`}
                icon="fa-thermometer-half"
                color="danger"
            />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
            <Card
                title={`Wilgotność - ${targetDevice.device?.name}`}
                value={`${targetDevice.properties.humidity?.value ?? '-'}`}
                icon="fa-percent"
                color="info"
            />
        </div>
        <div className="col-xl-6 col-lg-6">
          <HygrostatAndThermostatConfigAndActionsPanel />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <PropertiesChart
            deviceId={deviceId}
            title="Temperature and humidity chart"
            id="hygrostatAndThermostatChart"
            data={[
              {
                deviceId,
                property: 'temperature',
                labelName: 'Temp',
                mapValue: (value) => parseFloat(value),
                color: 'red'
              },
              {
                deviceId,
                property: 'humidity',
                labelName: 'Humi',
                mapValue: (value) => parseFloat(value),
                color: 'blue'
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};
