import React, { useContext } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { DevicesContext } from "../contexts/DevicesContext";
import { LocationsContext } from "../contexts/LocationsContext";
import { NavLink } from "react-router-dom";
const columns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    minWidth: 150,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    flex: 1,
    minWidth: 150,
    editable: true,
  },
  {
    field: "url",
    headerName: "Url",
    flex: 2,
    minWidth: 200,
    editable: true,
    renderCell: (params) => (
      <a target="_blank" rel="noreferrer" href={params.value}>
        {params.value}
      </a>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    minWidth: 120,
    editable: true,
  },
];

export const DevicesPage = () => {
  const { locations } = useContext(LocationsContext);
  const { devices } = useContext(DevicesContext);

  const deviceList = devices.get().map((device) => ({
    id: device.id,
    name: device.name,
    url: device.url,
    type: device.type,
    location: locations
      .get()
      .find((location) => location.id === device.locationId)?.name,
  }));

  return (
    <div className="container-fluid" style={{ height: "80%", width: "100%" }}>
      <div className="row">
        <div className="col">
          <h1 className="h3 mb-4 text-gray-800">Device List</h1>
        </div>
        <div className="col">
          <NavLink to="/devices/add">
            <button className="btn btn-primary float-end">Add Device</button>
          </NavLink>
        </div>
      </div>
      <DataGrid
        rows={deviceList}
        columns={columns}
        pageSize={10}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
};
