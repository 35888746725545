import React, { useContext, useEffect } from "react";
import { EnergyMeterActionsPanel } from "../components/smart-sensors/energy-meter/EnergyMeterActionsPanel";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import {PropertiesChart} from "../components/basic/PropertiesChart";
import {Card} from "../components/Card";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const EnergyMeterPage = () => {
  const { deviceId } = useParams();
  const { targetDevice, isDevicesFetched } = useContext(DevicesContext);

  useEffect(() => {
      if (isDevicesFetched && uuidRegex.test(deviceId)) {
          targetDevice.set(deviceId);
      }
  }, [deviceId, isDevicesFetched]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

  return (
    <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
            <Card
                title="Zużycie energii elektrycznej:"
                value={`${targetDevice.properties.energy?.value / 1000} kWh`}
                icon="fa-bolt"
                color="danger"
            />
        </div>
        <div className="col-xl-6 col-lg-6">
          {/*<EnergyMeterActionsPanel />*/} {/* TODO: cos tu nie dziala */}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <PropertiesChart
            deviceId={deviceId}
            title="Energy chart"
            id="energyChart"
            data={[{
              deviceId,
              property: 'energy',
              labelName: 'Energy',
              mapValue: (value) => (parseFloat(value) / 1000).toFixed(3),
              color: 'red'
            }]}
          />
        </div>
      </div>
    </div>
  );
};
