import React from "react";
import PropTypes from "prop-types";

export const Switch = (props) => {
  const inputs = props.options.map((input, index) => {
    return (
      <label
        key={index}
        className={`btn btn-secondary ${
          props.checked === input.value ? "active" : ""
        }`}
      >
        <input
          type="radio"
          name={input.name}
          value={input.value}
          checked={props.checked === input.value}
          onChange={props.callback.bind(this)}
        />{" "}
        {input.name}
      </label>
    );
  });

  return (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      {inputs}
    </div>
  );
};

Switch.propTypes = {
  callback: PropTypes.func.isRequired,
  checked: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
};
