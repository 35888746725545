import React, {useContext, useEffect, useState} from "react";
import { Card } from "../Card";
import CONFIG from '../../config.json';
import {AlertContext} from "../../contexts/AlertContext";
import {request} from "../../axios/axios-instance";

export const OutsideTemperatureCard = () => {
  const [temperature, setTemperature] = useState(0);
  const { alert } = useContext(AlertContext)

  useEffect(async () => {
    try {
      const response = await request
          .get(`${CONFIG.BACKEND_URL}/weather/temperature`)
      setTemperature(response.data.value);
    } catch (e) {
      alert.error(e);
    }
  }, []);

  return (
    <Card
      title="Temperatura na zewnątrz"
      value={`${temperature}`}
      icon="fa-thermometer-half"
      color="danger"
    />
  );
};
