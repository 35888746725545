import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import CONFIG from '../config.json'
import {useAuth0} from "@auth0/auth0-react";
import {AlertContext} from "./AlertContext";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const { logout, user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const alert = useContext(AlertContext);
  // const [userInMemory, setUserInMemory, removeUserInMemory] = useLocalStorage(
  //   "user",
  //   null
  // );
  // const [user, setUser] = useState(userInMemory);
  // console.log(user)
  const value = {
    user: {
      set: (u) => {
        // setUser(u);
        // setUserInMemory(u);
      },
      get: () => user,
      user,
      isLoggedIn: () => isAuthenticated,
      getToken: async () => await getAccessTokenSilently({
        audience: CONFIG.OAUTH2_AUDIENCE,
        scope: 'read:current_user'
      }),
      logout: () => {
        // removeUserInMemory();
        logout({returnTo: window.location.origin});
        // setUser(null);
      },
      login: () => {
        loginWithRedirect().catch(e => console.log)
      },
    },
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
