import axios from "axios";
import MD5 from "crypto-js/md5";

const axiosRequestsHashes = []

axios.interceptors.request.use(function (config) {
    const hash = MD5(`${config.url}${JSON.stringify(config.params)}`)

    if (axiosRequestsHashes.indexOf(hash) !== -1) {
        return new Promise(() => {});
    }

    axiosRequestsHashes.push(hash)

    return config;
});

axios.interceptors.response.use(function (response) {
    const hash = MD5(`${response.config.url}${JSON.stringify(response.config.params)}`)
    const index = axiosRequestsHashes.indexOf(hash)

    if (index !== -1) {
        axiosRequestsHashes.splice(index, 1);
    }

    return response
});