import React from "react";
import { Button, Menu as MaterialMenu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const MenuItemWithList = (props) => {
  const { callbacks, elements, devices } = props;
  const { handleClick, handleClose, handleSelectDevice } = callbacks;

  return Object.keys(elements).map((key, index) => {
    const deviceList = devices.get(elements[key].type).map((device) => {
      return (
        <MenuItem key={`menuItem-${device.id}`} className="collapse-item">
          <NavLink
            onClick={() => handleSelectDevice(device.id)}
            className="nav-link"
            to={`/${device.type}/${device.id}`}
          >
            {device.name}
          </NavLink>
        </MenuItem>
      );
    });

    return (
      <li className="nav-item" key={`li-element-${index}`}>
        <Button
          id={key}
          className="nav-link collapsed sidebar-button"
          onClick={handleClick}
          aria-controls={elements[key].menuId}
          aria-haspopup="true"
        >
          <i className={`fas fa-fw ${elements[key].icon}`}></i>
          <span>{elements[key].title}</span>
        </Button>
        <MaterialMenu
          id={elements[key].menuId}
          anchorEl={elements[key].anchorEl}
          keepMounted
          open={Boolean(elements[key].anchorEl)}
          onClose={handleClose}
          className="devices-menu"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Devices:</h6>
            {deviceList}
          </div>
        </MaterialMenu>
      </li>
    );
  });
};

MenuItem.poropTypes = {
  devices: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  callbacks: PropTypes.shape({
    handleClick: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSelectDevice: PropTypes.func.isRequired,
  }).isRequired,
};
