import React, {useContext} from "react";
import {DevicesContext} from "../../contexts/DevicesContext";

export const DevicePageTitle = () => {
  const { targetDevice } = useContext(DevicesContext);

  const statusIcon = (status) => {
    return status ? <i className="fas fa-check-circle text-success"/> : <i className="fas fa-check-circle text-danger"/>
  }

  return (
      <h1 className="h3 mb-4 text-gray-800">{statusIcon(targetDevice.isFetched)} {targetDevice.device?.name}</h1>
  );
};
