import React, { useContext, useEffect, useState } from "react";
import { CardBig } from "../../CardBig";
import PropTypes from "prop-types";
import { Switch } from "../../basic/Switch";
import CONFIG from '../../../config.json';
import { AlertContext } from "../../../contexts/AlertContext";
import { DevicesContext } from "../../../contexts/DevicesContext";
import {UserContext} from "../../../contexts/UserContext";
import {request} from "../../../axios/axios-instance";

const GATE_STATES = {
  OPENING: "opening",
  OPENED: "opened",
  STOPPED: "stopped",
  CLOSING: "closing",
  CLOSED: "closed",
};

const GATE_ACTIONS = {
  OPEN: "open",
  STOP: "stop",
  CLOSE: "close",
};

export const RemoteGateConfigPanel = (props) => {
  const [state, setState] = useState(GATE_STATES.STOPPED);
  const [temperatureInside, setTemperatureInside] = useState(0);
  const alert = useContext(AlertContext);
  const { user } = useContext(UserContext);
  const { targetDevice } = useContext(DevicesContext);

  const fetchData = async () => {
    try {
      let response = await request
          .authorized(await user.getToken())
          .get(
              `${CONFIG.BACKEND_URL}/devices/${
                  props.device?.id ?? targetDevice.device.id
              }/gate`
          )
      setState(response.data.value);

      response = await request
          .authorized(await user.getToken())
          .get(
              `${CONFIG.BACKEND_URL}/devices/${
                  props.device?.id ?? targetDevice.device.id
              }/temperature-inside`
          )

      setTemperatureInside(parseFloat(response.data.value));
    } catch (e) {
      alert.error(e)
    }
  };

  useEffect(async () => {
    if (!targetDevice.isFetched && !props.device) {
      return;
    }

    await fetchData();
  }, [targetDevice]);

  const handleRunAction = async (event) => {
    const action = event.target.value;

    try {
      await request
          .authorized(await user.getToken())
          .post(
              `${CONFIG.BACKEND_URL}/devices/${
                  props.device?.id ?? targetDevice.device.id
              }/actions/${action}`
          )

      await fetchData();
    } catch (e) {
      alert.error(e)
    }
  };

  const stateBadgeClass = {
    opening: "info",
    stopped: "warning",
    closing: "info",
    open: "success",
    close: "danger",
  };

  return (
    <CardBig title="Remote Gate">
      <div className="row">
        <div className="col-sm-4 vertical-center">Action:</div>
        <div className="col vertical-center">
          <Switch
            callback={handleRunAction}
            checked={state}
            options={[
              {
                name: "open",
                value: GATE_ACTIONS.OPEN,
              },
              {
                name: "stop",
                value: GATE_ACTIONS.STOP,
              },
              {
                name: "close",
                value: GATE_ACTIONS.CLOSE,
              },
            ]}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Gate state:</div>
        <div className="col vertical-center">
          <h5>
            <span className={`badge bg-${stateBadgeClass[state]}`}>
              {state}
            </span>
          </h5>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Temperature inside:</div>
        <div className="col vertical-center">
          <h2>{temperatureInside} °C</h2>
        </div>
      </div>
    </CardBig>
  );
};

RemoteGateConfigPanel.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
