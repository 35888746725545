import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CONFIG from '../config.json';
import { UserContext } from "./UserContext";
import { AlertContext } from "./AlertContext";
import { LocationsContext } from "./LocationsContext";
import {request} from "../axios/axios-instance";

export const DevicesContext = createContext();

export const DevicesProvider = React.memo((props) => {
  const [devices, setDevices] = useState([]);
  const [targetDevice, setTargetDevice] = useState(null);
  const [properties, setProperties] = useState({});
  const { user } = useContext(UserContext);
  const alert = useContext(AlertContext);
  const { targetLocation } = useContext(LocationsContext);
  const value = {
    devices: {
      get: (type = null) =>
        devices.filter(
          (device) =>
            ((type && device.type === type) || !type) &&
            ((targetLocation.location &&
              targetLocation.location.id === device.locationId) ||
              !targetLocation.location)
        ),
      fetch: async (type = "") => {
        try {
          const response = await request
            .authorized(await user.getToken())
            .get(`${CONFIG.BACKEND_URL}/devices`, {
              params: {
                type,
              },
            }
          )

          setDevices(response.data);
        } catch (e) {
          alert.error(e);
        }
      },
    },
    targetDevice: {
      device: targetDevice,
      properties,
      set: (deviceId) => setTargetDevice(devices.find((device) => device.id === deviceId)),
      isFetched: !!Object.keys(properties).length
    },
    isDevicesFetched: !!devices.length
  };

  useEffect(async () => {
    if (user.isLoggedIn()) {
      await value.devices.fetch();
    }
  }, [user.user]);

  useEffect(async () => {
    if (targetDevice == null) return

    setProperties({});

    try {
      const response = await request.authorized(await user.getToken()).get(
          `${CONFIG.BACKEND_URL}/devices/${targetDevice.id}/properties`
      )

      setProperties(response.data)
    } catch (e) {
      alert.error(e)
    }
  }, [targetDevice])

  return (
    <DevicesContext.Provider value={value}>
      {props.children}
    </DevicesContext.Provider>
  );
});

DevicesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.any,
};
