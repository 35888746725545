import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import { RemoteGateConfigPanel } from "../components/smart-sensors/remote-gate/RemoteGateConfigPanel";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const RemoteGatePage = () => {
  const { deviceId } = useParams();
  const { targetDevice, isDevicesFetched } = useContext(DevicesContext);

  useEffect(() => {
    targetDevice.set(deviceId);
  }, [deviceId, isDevicesFetched]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

  return (
    <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <RemoteGateConfigPanel />
        </div>
      </div>
    </div>
  );
};
