import React, { useContext, useEffect, useState } from "react";
import { CardBig } from "../../CardBig";
import PropTypes from "prop-types";
import { DevicesContext } from "../../../contexts/DevicesContext";

export const GasSensorCard = () => {
  const [temperature, setTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [gas, setGas] = useState(0);
  const [isGasDetected, setIsGasDetected] = useState(false);
  const { targetDevice } = useContext(DevicesContext);

  useEffect(async () => {
    if (!targetDevice.isFetched) {
      return;
    }

    setTemperature(targetDevice.properties?.temperature.value)
    setHumidity(targetDevice.properties?.humidity.value)
    setGas(targetDevice.properties?.gas.value)
    setIsGasDetected(targetDevice.properties?.isGasDetected.value == 0 ? false : true)

  }, [targetDevice]);

  const gasDetectionLabel = isGasDetected ? "detected" : "clear";

  return (
    <CardBig title="Gas sensor">
      <div className="row">
        <div className="col-sm-4 vertical-center">Temperature:</div>
        <div className="col vertical-center">
          <h5>{temperature} °C</h5>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Humidity:</div>
        <div className="col vertical-center">
          <h5>{humidity} %</h5>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Gas value:</div>
        <div className="col vertical-center">
          <h2>{gas}</h2>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-4 vertical-center">Gas detection:</div>
        <div className="col vertical-center">
          <h5>
            <span
              className={`badge bg-${isGasDetected ? "danger" : "success"}`}
            >
              {gasDetectionLabel}
            </span>
          </h5>
        </div>
      </div>
    </CardBig>
  );
};

GasSensorCard.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
