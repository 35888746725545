import React, {useContext, useEffect} from "react";
import { useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/DevicesContext";
import { uuidRegex } from "../helpers/Validators";
import {Card} from "../components/Card";
import {GrowboxConfigPanel} from "../components/smart-sensors/growbox/GrowboxConfigPanel";
import {GrowboxActionPanel} from "../components/smart-sensors/growbox/GrowboxActionPanel";
import {PropertiesChart} from "../components/basic/PropertiesChart";
import {DevicePageTitle} from "../components/basic/DevicePageTitle";

export const GrowboxPage = () => {
  const { deviceId } = useParams();
  const { targetDevice, devices } = useContext(DevicesContext);

  useEffect(async () => {
    targetDevice.set(deviceId);
  }, [deviceId, devices]);

  if (!uuidRegex.test(deviceId)) {
    return <span>Id is invalid</span>;
  }

    return (
      <div className="container-fluid">
      <DevicePageTitle />
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
              title={`Temperatura wewnątrz`}
              value={`${targetDevice.properties.temperatureInside?.value}`}
              icon="fa-thermometer-half"
              color="danger"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
              title={`Wilgotność wewnątrz`}
              value={`${targetDevice.properties.humidityInside?.value}`}
              icon="fa-percent"
              color="info"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
              title={`Temperatura na zewnątrz`}
              value={`${targetDevice.properties.temperatureOutside?.value}`}
              icon="fa-thermometer-half"
              color="danger"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
              title={`Wilgotność na zewnątrz`}
              value={`${targetDevice.properties.humidityOutside?.value}`}
              icon="fa-percent"
              color="info"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <GrowboxConfigPanel />
        </div>
        <div className="col-xl-6 col-lg-6">
          <GrowboxActionPanel />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <PropertiesChart
            title="All data chart"
            id="growboxTemperatureChart"
            data={[
              {
                deviceId,
                property: 'temperatureInside',
                labelName: 'Temp. in',
                mapValue: (value) => parseFloat(value),
                color: 'red'
              },
              {
                deviceId,
                property: 'temperatureOutside',
                labelName: 'Temp. out',
                mapValue: (value) => parseFloat(value),
                color: 'orange'
              },
              {
                deviceId,
                property: 'humidityInside',
                labelName: 'Humi. in',
                mapValue: (value) => parseFloat(value),
                color: 'blue'
              },
              {
                deviceId,
                property: 'humidityOutside',
                labelName: 'Humi. out',
                mapValue: (value) => parseFloat(value),
                color: 'purple'
              },
              {
                deviceId,
                property: 'hps',
                labelName: 'HPS',
                mapValue: (value) => parseInt(value) * 100,
                color: 'yellow'
              },
              {
                deviceId,
                property: 'heater',
                labelName: 'Heater',
                mapValue: (value) => parseInt(value) * 100,
                color: 'brown'
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};
