import React from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import { mergeDeep } from "../../helpers/MergeDeepHelper";
import zoomPlugin from "chartjs-plugin-zoom";
import "hammerjs";
import DatePicker from "react-datepicker";
import { CardBig } from "../CardBig";
import "chartjs-adapter-moment";
import {Chart, LinearScale, LineElement, PointElement, Title, TimeScale} from "chart.js";

const colors = {
  red: {
    background: "rgba(255, 99, 132, 0.2)",
    border: "rgba(255, 99, 132, 1)",
  },
  green: {
    background: "rgba(67, 177, 119, 0.2)",
    border: "rgba(67, 177, 119, 1)",
  },
  blue: {
    background: "rgba(67, 93, 177, 0.2)",
    border: "rgba(67, 93, 177, 1)",
  },
  orange: {
    background: "rgba(220, 131, 5, 0.2)",
    border: "rgb(220, 131, 5)",
  },
  yellow: {
    background: "rgba(225, 199, 22, 0.2)",
    border: "rgb(225, 199, 22)",
  },
  brown: {
    background: "rgba(87, 57, 57, 0.2)",
    border: "rgb(87, 57, 57)",
  },
  purple: {
    background: "rgba(155, 52, 182, 0.2)",
    border: "rgb(155, 52, 182)",
  }
};

Chart.register(zoomPlugin, LineElement, PointElement, LinearScale, Title, TimeScale)

export const MultiLineChart = (props) => {
  const options = mergeDeep(
    {
      responsive: true,
      scales: {
        x: {
          type: "time",
          // distribution: "series",
          time: {
            displayFormats: {
              millisecond: "H:mm:ss",
              second: "H:mm:ss",
              minute: "H:mm",
              hour: "MM.DD H:mm",
              day: "MM.DD",
              week: "MM.DD",
              month: "MM.DD",
              quarter: "MM.DD",
              year: "YYYY.MM.DD",
            },
          },
        },
      },
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: "xy",
          },
          pan: {
            enabled: true,
            mode: "xy",
          },
        },
      },
    },
    props.options
  );

  const datePickerFrom = props.datetimeFrom && (
    <DatePicker
      className="form-control"
      selected={props.datetimeFrom.value}
      onChange={(date) => props.datetimeFrom.callback(date)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
    />
  );

  const datePickerTo = props.datetimeTo && (
    <DatePicker
      className="form-control"
      selected={props.datetimeTo.value}
      onChange={(date) => props.datetimeTo.callback(date)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
    />
  );

  const resolutionValues = ["1MIN", "15MIN", "30MIN", "1H", "6H", "12H", "1D"];
  const resolutionOptions = resolutionValues.map((value) => (
    <option key={`option-${value}`} value={value}>
      {value}
    </option>
  ));
  const resolution = (
    <select
      value={props.resolution.value}
      className="form-control"
      onChange={props.resolution.callback}
    >
      {resolutionOptions}
    </select>
  );

  const fill = props.data.length <= 1

  return (
    <CardBig title={props.title}>
      <div className="chart-area">
        <Line
          type="line"
          data={{
            labels: props.labels,
            datasets: props.data.map((dataset => ({
              label: dataset.labelName,
              data: dataset.data,
              backgroundColor: [colors[dataset.color].background],
              borderColor: [colors[dataset.color].border],
              borderWidth: 1,
              pointRadius: 0,
              fill
            }))),
          }}
          options={options}
        />
      </div>
      <hr />
      <div className="row">
        <div className="col">Resolution: {resolution}</div>
        <div className="col">From: {datePickerFrom}</div>
        <div className="col">To: {datePickerTo}</div>
      </div>
    </CardBig>
  );
};

MultiLineChart.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  options: PropTypes.object,
  datetimeFrom: PropTypes.exact({
    value: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired,
  }),
  datetimeTo: PropTypes.exact({
    value: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired,
  }),
  resolution: PropTypes.exact({
    value: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.array.isRequired,
    labelName: PropTypes.string.isRequired,
    color: PropTypes.oneOf(["red", "green", "blue", "yellow", "orange", "purple", "brown"]),
  })),
};

MultiLineChart.defaultProps = {
  options: {},
  datetimeFrom: null,
  datetimeTo: null,
  resolution: null,
};
