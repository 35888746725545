import { DeviceTypes } from "../../types/deviceTypes";

export const menuDevicesTypes = {
  hygrostatAndThermostat: {
    title: "Thermostat & hygrostat",
    menuId: "menu-hygrostat-and-thermostat",
    type: DeviceTypes.hygrostatAndThermostat,
    anchorEl: null,
    icon: "fa-thermometer-half",
  },
  energyMeter: {
    title: "Energy meter",
    menuId: "menu-energy-meter",
    type: DeviceTypes.energyMeter,
    anchorEl: null,
    icon: "fa-bolt",
  },
  remoteGate: {
    title: "Remote gate",
    menuId: "menu-remote-gate",
    type: DeviceTypes.remoteGate,
    anchorEl: null,
    icon: "fa-dungeon",
  },
  gasSensor: {
    title: "Gas sensor",
    menuId: "menu-gas-sensor",
    type: DeviceTypes.gasSensor,
    anchorEl: null,
    icon: "fa-smoking-ban",
  },
  smartPlug: {
    title: "Smart plug",
    menuId: "menu-smart-plug",
    type: DeviceTypes.smartPlug,
    anchorEl: null,
    icon: "fa-plug",
  },
  growbox: {
    title: "GrowBox",
    menuId: "menu-growbox",
    type: DeviceTypes.growbox,
    anchorEl: null,
    icon: "fa-box"
  },
  ambientSensor: {
    title: "Ambient sensor",
    menuId: "menu-ambient-sensor",
    type: DeviceTypes.ambientSensor,
    anchorEl: null,
    icon: "fa-tachometer-alt"
  }
};
