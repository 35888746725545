import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { MenuLocations } from "./MenuLocations";
import { mergeDeep } from "../../helpers/MergeDeepHelper";
import { DevicesContext } from "../../contexts/DevicesContext";
import { MenuItemWithList } from "./MenuItemWithList";
import { menuDevicesTypes } from "./menuDevicesTypes";

export const Menu = (props) => {
  const [menuElements, setMenuElements] = React.useState(menuDevicesTypes);
  const { devices, targetDevice } = useContext(DevicesContext);

  const handleClick = (event) => {
    const menuElementsLocal = mergeDeep({}, menuDevicesTypes);
    const menuElement = menuElementsLocal[event.currentTarget.id];

    menuElementsLocal[event.currentTarget.id] = {
      ...menuElement,
      anchorEl: event.currentTarget,
    };

    setMenuElements(menuElementsLocal);
  };

  const handleClose = () => {
    setMenuElements(menuDevicesTypes);
  };

  const handleSelectDevice = (deviceId) => {
    targetDevice.set(deviceId);

    handleClose();
  };

  return (
      <ul
          className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
              props.menuVisibility ? "toggled" : ""
          }`}
          id="accordionSidebar"
      >
        <a
            rel="noreferrer"
            className="sidebar-brand d-flex align-items-baseline justify-content-center"
            href="/"
        >
          <div className="sidebar-brand-icon">
            <i className="fas fa-home"/>
          </div>
          <div className="sidebar-brand-text mx-2">Smarthome</div>
        </a>

        <hr className="sidebar-divider my-0"/>

        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            <i className="fas fa-fw fa-tachometer-alt"/>
            <span>Dashboard</span>
          </NavLink>
          <NavLink className="nav-link" to="/house-plan">
            <i className="fas fa-fw fa-home"/>
            <span>House plan</span>
          </NavLink>
        </li>

        <hr className="sidebar-divider"/>

        <MenuLocations/>

        <div className="sidebar-heading">Devices</div>

        <MenuItemWithList
            callbacks={{handleClick, handleClose, handleSelectDevice}}
            devices={devices}
            elements={menuElements}
        />

        <hr className="sidebar-divider"/>

        <li className="nav-item">
          <NavLink className="nav-link" to="/devices">
            <i className="fas fa-fw fa-tachometer-alt"/>
            <span>Devices</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <a
              href="/#"
              className="nav-link"
              onClick={() => window.location.reload()}
          >
            <i className="fas fa-fw fa-redo-alt"/>
            <span>Refresh</span>
          </a>
        </li>

        <hr className="sidebar-divider d-none d-md-block"/>

        <div className="text-center d-none d-md-inline">
          <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => props.toggleMenu()}
          />
        </div>
      </ul>
  );
};

Menu.poropTypes = {
  menuVisibility: PropTypes.bool,
  toggleMenu: PropTypes.func,
};
