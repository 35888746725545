import React from "react";
import PropTypes from "prop-types";

export const Card = (props) => {
  return (
    <div className={`card border-left-${props.color} shadow h-100 py-2`}>
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col me-2">
            <div
              className={`text-xs fw-bold text-${props.color} text-uppercase mb-1`}
            >
              {props.title}
            </div>
            <div className="mb-0 fw-bold text-gray-800">
              <h2 className="fa-5x">{props.value}</h2>
            </div>
          </div>
          <div className="col-auto">
            <i className={`fas ${props.icon} fa-4x text-gray-900 absolute-icon`}></i>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "success",
    "info",
    "danger",
    "warning",
    "secondary",
  ]),
};

Card.defaultProps = {
  icon: "fa-home",
  color: "primary",
};
