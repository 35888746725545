import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Button, Menu, MenuItem } from "@material-ui/core";

export const UserProfile = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    user.login();
    handleClose();
  };

  const handleLogout = () => {
    user.logout();
    handleClose();
  };

  const loginLogoutButton = user.isLoggedIn() ? (
    <MenuItem onClick={handleLogout}>
      <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400" />
      Logout
    </MenuItem>
  ) : (
    <MenuItem onClick={handleLogin}>
      <i className="fas fa-sign-in-alt fa-sm fa-fw me-2 text-gray-400" />
      Login
    </MenuItem>
  );

  const avatar = user.isLoggedIn() ? <img
      className="img-profile rounded-circle"
      src={user.get().picture}
      alt={user.get().picture}
  /> : null

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="nav-link dropdown-toggle"
        role="button"
      >
        <span className="me-2 d-none d-lg-inline text-gray-600 small">
          {user.isLoggedIn() ? user.user.name : "unknown"}
        </span>
        {avatar}
      </Button>
      <Menu
        id="customized-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
      >
        <MenuItem value="">
          <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400" />
          Profile
        </MenuItem>
        <MenuItem value={10}>
          <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400" />
          Settings
        </MenuItem>
        <div className="dropdown-divider" />
        {loginLogoutButton}
      </Menu>
    </div>
  );
};
