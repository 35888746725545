import React from "react";
import { HygrostatAndThermostatHumidityCard } from "../components/smart-sensors/hygrostat-and-thermostat/HygrostatAndThermostatHumidityCard";
import { RemoteGateConfigPanel } from "../components/smart-sensors/remote-gate/RemoteGateConfigPanel";
import { OutsideTemperatureCard } from "../components/weather/OutsideTemperatureCard";
import {HousePlanComponent} from "../components/house-plan/HousePlanComponent";

export const MainPage = () => {
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Main Page</h1>
        <div className="row">
            <div className="col-xl-2 col-md-3 col-sm-4 col-6 mb-4">
                <OutsideTemperatureCard/>
            </div>
            <div className="col-xl-2 col-md-3 col-sm-4 col-6 mb-4">
                <HygrostatAndThermostatHumidityCard
                    device={{
                        id: "3a80a579-cf91-4edb-8df4-674f5241a8d5",
                        name: "Gym Thermostat 1",
                    }}
                />
            </div>
            <div className="col-xl-6 col-lg-6">
                <RemoteGateConfigPanel
                    device={{
                        id: "83228c90-24b7-4a7e-8323-b91651bd374d",
                        name: "Remote Gate Syrokomli",
                    }}
                />
            </div>
            <div className="row">
            </div>
            {/*<div className="col-xl-6 col-lg-6">*/}
            {/*  <LedRgbStripCard />*/}
            {/*</div>*/}
            <div className="col-xl-6 col-lg-6">
                <HousePlanComponent/>
            </div>
        </div>
    </div>
  );
};
