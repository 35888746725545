import React from "react";
import PropTypes from "prop-types";

export const CardBig = (props) => {
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 fw-bold text-primary">{props.title}</h6>
      </div>
      <div className="card-body">{props.children}</div>
    </div>
  );
};

CardBig.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
