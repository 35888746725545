import React, { useContext, useEffect, useState } from "react";
import { Card } from "../../Card";
import PropTypes from "prop-types";
import CONFIG from '../../../config.json';
import { DevicesContext } from "../../../contexts/DevicesContext";
import {request} from "../../../axios/axios-instance";
import {UserContext} from "../../../contexts/UserContext";
import {AlertContext} from "../../../contexts/AlertContext";

export const HygrostatAndThermostatHumidityCard = (props) => {
  const { targetDevice } = useContext(DevicesContext);
  const { user } = useContext(UserContext);
  const alert = useContext(AlertContext)
  const [humidity, setHumidity] = useState(55);

  useEffect(async () => {
    if (!targetDevice.isFetched && !props.device) {
      return;
    }

    // try {
    //   const response = await request
    //     .authorized(await user.getToken())
    //     .get(
    //         `${CONFIG.BACKEND_URL}/devices/${
    //             props.device?.id ?? targetDevice.device.id
    //         }/humidity`
    //     )
    //
    //   setHumidity(response.data.value);
    // } catch (e) {
    //   alert.error(e)
    // }
  }, [targetDevice]);

  return (
    <Card
      title={`Wilgotność - ${props.device?.name ?? targetDevice.device?.name}`}
      value={`${humidity}`}
      icon="fa-percent"
      color="info"
    />
  );
};

HygrostatAndThermostatHumidityCard.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
